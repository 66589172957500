import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";
import { getLocaleString } from "utils/localization/locale";

let savedFilters = getUXSettingsFromStorage("ANALYTICS_NOTES_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    from_date: sd,
    to_date: ed,
  };
}

export const NOTES_LIST_COLUMNS = {
  [getLocaleString("save_search.created_by", "Created By")]: true,
  [getLocaleString("common.invoiceNumber", "Invoice Number")]: true,
  [getLocaleString("note.date_created", "Date Created")]: true,
  [getLocaleString("common.invoiceDate", "Invoice Date")]: true,
  [getLocaleString("common.dueDate", "Due Date")]: false,
  [getLocaleString("common.payIQDate", "PayIQ Date")]: false,
  [getLocaleString("common.daysPastDue", "Days Past Due")]: false,
  [getLocaleString("common.note", "Note")]: true,
  [getLocaleString("common.status", "Status")]: true,
  [getLocaleString("dashboard.payment_terms", "Payment Terms")]: false,
};

export const NOTES_LIST_COLUMNS_ORDER = {
  "Created By": 1,
  Customer: 2,
  "Customer Category": 3,
  "Invoice Number": 4,
  "Date Created": 5,
  "Invoice Date": 6,
  "Due Date": 7,
  "PayIQ Date": 8,
  "Days Past Due": 9,
  Note: 10,
  Status: 11,
  "Payment Terms": 12,
};

export const initialNotesListFilters = {
  page: 1,
  sort_by: "date",
  sort_order: "desc",
  followed_by: null,
  from_date: moment("01/01/1970").format("MM/DD/YYYY"),
  to_date: moment().add(1, "years").format("MM/DD/YYYY"),
  invoice_id: null,
  supplier_id: null,
  invoice_status: "",
  client_customer_rel_id: null,
  user_type:"" 
};

export const initialData = {
  listData: [],
  usersList: [],
  isFetching: false,
  filters: {
    ...initialNotesListFilters,
    ...savedFilters,
  },
  association:
    savedFilters && savedFilters.association
      ? savedFilters.association
      : "Customer",
  exportedData: [],
  showexport: false,
  exporting: false,
};

const globalNotesList = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SET_NOTES_LIST:
      return {
        ...state,
        ...payload.data,
      };
    case ReducersTypes.SET_NOTES_LIST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload.data,
        },
      };
    case ReducersTypes.RESET_NOTES_LIST:
      return {
        ...initialData,
        usersList: state.usersList,
        filters: {
          ...initialNotesListFilters,
        },
        association: payload ? payload.data.association : "Customer",
      };
    default:
      return state;
  }
};

export default globalNotesList;
